export default {
    a: "At Snstec, we are more than just a software development company—we are your dedicated partners in digital transformation. Our commitment to quality and innovation ensures that our clients receive robust, high-performance software tailored to their unique needs.",
    b: "At the heart of Snstec is a team of passionate professionals who thrive on challenges and embrace creativity. We understand that every business is different, which is why we take the time to listen, learn, and collaborate closely with our clients to understand their goals and challenges.",
    c: "Our mission is simple: to empower businesses through cutting-edge technology and strategic software solutions. From concept to deployment, we guide our clients every step of the way, leveraging our expertise in software development to drive tangible results and sustainable growth",
    d: "At Snstec, we believe in forging long-lasting partnerships built on trust, transparency, and integrity. With our unwavering dedication to client satisfaction and our relentless pursuit of excellence, we are committed to helping businesses thrive in today's digital landscape. ",
    e: "Join us at Snstec and experience the difference that innovative software solutions can make for your business. Let's embark on this journey together and unlock the full potential of your digital future. ",


    f: "Snstec is a leading-edge in software development and technology-driven solutions. Specializing in a comprehensive range of services, we excel in crafting bespoke software solutions tailored to your unique needs. ",
    g: "years of operation",
    h: "projects delivered",
    i: "team specialist",

}




