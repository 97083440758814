export default {
    a: 'Visit Our Office',
    b: 'Call Us',
    c: 'Mail Us',
    d: '姓名',
    e: '邮箱',
    f: '电话',
    g: '业务范围',
    g1:'网站开发（Web Development）',
    g2:'网站设计（Web Design）',
    g3:'数字营销（Digital Marketing）',
    g4:'搜索优化（Search SEO）',
    h: '留言',
    i: '发送',
}