export default {
    dev: {
        j: "Software Development",
        k: "Our bespoke software development services are designed to seamlessly integrate with your company's unique needs, products, and objectives. By tailoring each component of our services to your specifications, we ensure that your software solutions are perfectly aligned with your business goals. ",
        l: "Service Description",
        m: "Choose our software development services, and your business can rely on 100% transparency. From our custom quotes to our initial designs, our team provides your company with complete access to our software development process. Get a free, accurate, and instant quote for our software development services with our easy-to-use calculator. Our team customizes our software development services to your company's specific requirements, whether it's web application development, mobile application development, or IaaS software development.",
        n: "We enhance the outcomes of your software development projects by ensuring that they adhere to best practices in software architecture and design. Whether you're building web applications, mobile applications, or infrastructure as a service (IaaS) software solutions, our team ensures that your projects are optimized for performance, scalability, and security. With our expertise in software architecture and development, we help you achieve your digital transformation goals and drive business success. ",
        o: "By leveraging our software development expertise, you can elevate your digital presence, enhance your operational efficiency, and unlock new opportunities for growth. Partner with us and experience the difference that customized software solutions can make for your business. ",

        p: "In the realm of software development, optimization encompasses a spectrum of strategies aimed at enhancing your application's visibility and performance in relevant searches. Achieving a higher ranking in search results is paramount in ensuring increased visibility and user engagement. ",
        q: "Choose our software development services, and your business can rely on complete transparency. From personalized quotes to initial designs, our team grants your organization full access to our development process. Obtain a free, precise, and instant quote for our software development services using our user-friendly calculator. Our team customizes our software development services to meet your company's unique needs. This allows you to tailor every aspect of our services to your company, products, services, and objectives. ",
    },
    ai: {
        j: "AI, Machine Learning, and Data Analytics",
        k: "Our AI, machine learning, and data analytics services are meticulously crafted to meet the diverse needs of modern businesses. We offer bespoke solutions tailored to your organization's specific requirements, ensuring seamless integration and maximum efficiency. ",
        l: "Service Description ",
        m: "Choose our AI, machine learning, and data analytics services to unlock the full potential of your data. From predictive analytics to natural language processing, our team provides comprehensive solutions that drive actionable insights and informed decision-making. ",
        n: "Gain access to cutting-edge technology and expertise as we guide you through every step of the process. Our transparent approach ensures that you have complete visibility into the development and implementation of your AI, machine learning, and data analytics projects. ",
        o: "Whether you're looking to optimize business processes, enhance customer experiences, or gain a competitive edge, our team is dedicated to delivering results that exceed your expectations. Join us on the forefront of innovation and harness the power of AI, machine learning, and data analytics to propel your business forward. ",

        p: "In the realm of AI, machine learning, and data analytics, a diverse range of strategies is employed to enhance your system's performance and relevance in relevant searches. Achieving a higher ranking in search results is crucial for maximizing visibility and accessibility of your solutions. ",
        q: "Choose our AI, machine learning, and data analytics services, and your business can rely on complete transparency. From personalized quotes to initial plans, our team grants your organization full access to our development process. Obtain a free, precise, and instant quote for our AI, machine learning, and data analytics services using our user-friendly calculator. Our team tailors these services to meet your company's unique requirements. This allows you to customize every aspect of our offerings to your organization, products or services, and objectives.",
    }
}