import {Link} from "react-router-dom";
import React from "react";
import {useTranslation} from "react-i18next";

const SendMessage = () => {
    const {t} = useTranslation();
    return (

        <section className="about-section pt-120">
            <div className="about-element-one two">
                <img src="assets/images/element/element-39.png" alt="element"/>
            </div>
            <div className="container">
                <div className="about-area">
                    <div className="row justify-content-center align-items-center mb-30-none">
                        <div className="col-xl-6 col-lg-6 mb-30">
                            <div className="box-wrapper two">
                                <div className="box3"></div>
                                <div className="box1">
                                    <div className="box-element-one">
                                        <img src="assets/images/element/element-59.png" alt="element"/>
                                    </div>
                                    <div className="box-element-two">
                                        <img src="assets/images/element/element-7.png" alt="element"/>
                                    </div>
                                </div>
                                <div className="box2">
                                    <div className="box-element-five">
                                        <img src="assets/images/element/element-16.png" alt="element"/>
                                    </div>
                                    <div className="box-element-six">
                                        <img src="assets/images/element/element-7.png" alt="element"/>
                                    </div>
                                </div>
                            </div>
                            <div className="about-thumb">
                                <img src="assets/images/element/element-58.png" alt="element"/>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 mb-30">
                            <div className="about-content">
                                <h2 className="title">{t('home.m')}<span
                                    className="text--base">{t('home.n')}</span></h2>
                                <p className="para">{t('home.o')}</p>
                                <p>{t('home.p')}</p>
                                <div className="about-btn">
                                    {/*<a href="contact.html" className="btn--base">Send Message</a>*/}
                                    <Link to="/contact" className="btn--base">{t('app.sendMessage')}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default SendMessage