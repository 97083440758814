import React from "react";
import {Link} from "react-router-dom";
import SendMessage from "../components/SendMessage";
import Strength from "../components/Strength";
// import element5 from '../../public/assets/images/element/element-5.png'
// import element6 from '../../public/assets/images/element/element-6.png'
// import element7 from '../../public/assets/images/element/element-7.png'
// import element69 from '../../public/assets/images/element/element-69.png'
// import element70 from '../../public/assets/images/element/element-70.png'

import icon3 from '../images/icon/icon-3.png'
import icon4 from '../images/icon/icon-4.png'
// import icon5 from 'assets/images/icon/icon-5.png'
// import icon6 from 'assets/images/icon/icon-6.png'
// import icon10 from 'assets/images/icon/icon-10.png'
// import icon11 from 'assets/images/icon/icon-11.png'
// import icon12 from 'assets/images/icon/icon-12.png'
// import icon13 from 'assets/images/icon/icon-13.png'
// import element34 from 'assets/images/element/element-34.png'
// import element35 from 'assets/images/element/element-35.png'
// import element36 from 'assets/images/element/element-36.png'
import {useTranslation} from "react-i18next";

const Services = () => {
    const {t} = useTranslation();
    const list = [
        {
            icon: icon3,
            title: t('home.i'),
            desc: t('home.j'),
            route: '/softwareDevelopment'
        }, {
            icon: icon4,
            title: t('home.k'),
            desc: t('home.l'),
            route: '/ai'
        }
    ]
    return (
        <div>

            <section className="banner-section two inner">
                <div className="banner-element-four two">
                    <img src="assets/images/element/element-5.png" alt="element"/>
                </div>
                <div className="banner-element-five two">
                    <img src="assets/images/element/element-7.png" alt="element"/>
                </div>
                <div className="banner-element-nineteen two">
                    <img src="assets/images/element/element-6.png" alt="element"/>
                </div>
                <div className="banner-element-twenty-two two">
                    <img src="assets/images/element/element-69.png" alt="element"/>
                </div>
                <div className="banner-element-twenty-three two">
                    <img src="assets/images/element/element-70.png" alt="element"/>
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center mb-30-none">
                        <div className="col-xl-12 mb-30">
                            <div className="banner-content two">
                                <div className="banner-content-header">
                                    <h2 className="title">{t('app.services')} </h2>
                                    <div className="breadcrumb-area">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    {/*<a href="index.html">Home</a>*/}
                                                    <Link to="/">{t('app.home')} </Link>
                                                </li>
                                                <li className="breadcrumb-item active" aria-current="page">{t('app.services')} </li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <a href="#" className="scrollToTop"><i className="las la-angle-double-up"></i></a>
            <section className="service-section two ptb-120">
                <div className="service-element-one">
                    <img src="assets/images/element/element-34.png" alt="element"/>
                </div>
                <div className="service-element-two">
                    <img src="assets/images/element/element-35.png" alt="element"/>
                </div>
                <div className="service-element-three">
                    <img src="assets/images/element/element-36.png" alt="element"/>
                </div>
                <div className="service-element-four">
                    <img src="assets/images/element/element-36.png" alt="element"/>
                </div>
                <div className="container">
                    <div className="row justify-content-center mb-30-none">
                        {
                            list.map((item, index) => {
                                return (
                                    //col-xl-3 col-lg-4
                                    <div className="col-md-6 col-sm-6 mb-30">
                                        <div className="service-item three">
                                            <div className="service-icon">
                                                <img src={item.icon} alt="icon"/>
                                            </div>
                                            <div className="service-content">

                                                <h3 className="title">
                                                    <Link to={item.route}>{item.title}</Link>
                                                </h3>
                                                <p style={{minHeight:'55px'}}>{item.desc}</p>
                                                <div className="service-btn">
                                                    <Link to={item.route} className="custom-btn">{t('services.f')}<i
                                                        className="icon-Group-2361 ml-2"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                            })}
                    </div>
                </div>
            </section>

            <Strength/>
            <section className="process-section ptb-120">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-7 col-lg-8 text-center">
                            <div className="section-header">
                                <h2 className="section-title mb-0">{t('services.a')}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mb-30-none">
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 mb-30">
                            <div className="process-item text-center">
                                <div className="process-icon-area">
                                    <div className="process-element">
                                        <div className="process-number">
                                            <span>01</span>
                                        </div>
                                        <div className="process-dot">
                                            <span></span>
                                        </div>
                                    </div>
                                    <div className="process-icon">
                                        <img src="assets/images/icon/icon-22.png" alt="icon"/>
                                    </div>
                                </div>
                                <div className="process-content">
                                    <h3 className="title">{t('services.b')}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 mb-30">
                            <div className="process-item text-center">
                                <div className="process-icon-area">
                                    <div className="process-element">
                                        <div className="process-number">
                                            <span>02</span>
                                        </div>
                                        <div className="process-dot">
                                            <span></span>
                                        </div>
                                    </div>
                                    <div className="process-icon">
                                        <img src="assets/images/icon/icon-23.png" alt="icon"/>
                                    </div>
                                </div>
                                <div className="process-content">
                                    <h3 className="title">{t('services.c')}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 mb-30">
                            <div className="process-item text-center">
                                <div className="process-icon-area">
                                    <div className="process-element">
                                        <div className="process-number">
                                            <span>03</span>
                                        </div>
                                        <div className="process-dot">
                                            <span></span>
                                        </div>
                                    </div>
                                    <div className="process-icon">
                                        <img src="assets/images/icon/icon-24.png" alt="icon"/>
                                    </div>
                                </div>
                                <div className="process-content">
                                    <h3 className="title">{t('services.d')}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 mb-30">
                            <div className="process-item text-center">
                                <div className="process-icon-area">
                                    <div className="process-element">
                                        <div className="process-number">
                                            <span>04</span>
                                        </div>
                                        <div className="process-dot">
                                            <span></span>
                                        </div>
                                    </div>
                                    <div className="process-icon">
                                        <img src="assets/images/icon/icon-25.png" alt="icon"/>
                                    </div>
                                </div>
                                <div className="process-content">
                                    <h3 className="title">{t('services.e')}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SendMessage/>
        </div>
    )
}
export default Services