import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import en from './en/index'
import tw from './tw/index'
import zh from './zh/index'

const lng = 'en';
i18n.use(initReactI18next)
    .init({
        resources: {
            en:{
                translation: en
            },
            zh: {
                translation:zh,
            },
            tw: {
                translation:tw,
            },
        },
        lng: lng,
        fallbackLng: lng,
        interpolation: {
            escapeValue: false,
        },
    });