export default {
    dev: {
        j: "软件开发",
        k: "我们的特色软件开发服务旨在与贵公司的独特需求、产品和目标无缝整合。我们根据您的具体要求量身定制服务的每个组成部分，确保您的软件解决方案与您的业务目标完美契合。 ",

        l: "服务描述",
        m: "选择我们的软件开发服务，您的企业将获得 100% 的透明度。从定制报价到初步设计，我们的团队为贵公司提供完整的软件开发流程。使用我们易于使用的计算器，获得免费、准确且即时的报价。无论是网络应用程序开发、移动应用程序开发，还是 IaaS 软件开发，我们的团队都能根据贵公司的具体要求定制软件开发服务。 ",
        n: "我们通过确保软件开发项目符合软件架构和设计的最佳实践来提升您的软件开发项目成果。无论您是要构建网络应用程序、移动应用程序，还是基础设施即服务（IaaS）软件解决方案，我们的团队都能确保您的项目在性能、可扩展性和安全性方面得到优化。凭藉我们在软件架构和开发方面的专业知识，可以帮助您实现数字化转型目标，推动业务成功。 ",
        o: "通过充分利用我们的软件开发专业知识，您可以提升您的数字化形象、增强运营效率并开启新的成长机会。与我们合作，体验定制软件解决方案为贵公司带来的不同。",

        p: "在软件开发领域中，优化涵盖了一系列策略，旨在提升您的应用程式在相关搜索中的可见性和性能。在搜索结果中获得更高的排名对于确保增加可见性和用户参与度至关重要。 ",
        q: "选择我们的软件开发服务，您的企业可以完全放心。从个性化报价到初步设计，我们的团队让您的企业完全了解我们的开发流程。使用我们用户友好的计算器，为我们的软件开发服务获得免费、精确和即时的报价。我们的团队根据贵公司的独特需求定制我们的软件开发服务，这使您能够根据贵公司、产品、服务和目标量身定制我们服务的每个方面。 ",
    },
    ai: {
        j: "人工智能（AI）、机器学习（Machine Learning）和数据分析",
        k: "我们的人工智能、机器学习和数据分析服务经过精心打造，以满足现代企业的多样需求。我们提供量身定制的解决方案，根据贵组织的具体要求进行调整，确保无缝整合和最大效率。 ",

        l: "服务描述",
        m: "选择我们的人工智能、机器学习和数据分析服务，发挥您数据的全部潜力。从预测分析到自然语言处理，我们的团队提供全面的解决方案，使得您的决策更具洞察力和可行性。",
        n: "通过我们的引导，您可以获得尖端技术和专业知识。我们确保您对人工智能、机器学习和数据分析项目的开发和实施过程有完全的了解。 ",
        o: "无论您是希望优化业务流程、增强客户体验还是获得竞争优势，我们的团队致力于提供超出您期望的结果。加入我们，站在创新的前沿，善用人工智能、机器学习和数据分析的力量，推动贵公司向前发展",

        p: "在人工智能（AI）、机器学习（Machine Learning）和数据分析领域，我们採用多种策略来提升您系统在相关搜索中的性能和相关性。在搜索结果中获得更高的排名对于最大限度地提高解决方案的可见性和可访问性至关重要。",
        q: "选择我们的人工智能（AI）、机器学习（Machine Learning）和数据分析服务，贵公司可以完全放心。从个性化报价到初步计划，我们的团队让您的企业完全了解我们的开发流程。使用我们简单易用的计算器，可获取人工智能、机器学习和数据分析服务的免费、精确且即时的报价。我们的团队根据贵公司的独特需求量身定制这些服务。这使您能够根据贵公司、产品或服务以及目标定制我们所提供服务的每个方面。 ",
    }

}