import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import $ from 'jquery';
import {CSSPlugin, gsap} from 'gsap';

gsap.registerPlugin(CSSPlugin);
const Cursor = () => {
    const follower = useRef<any>();
    const cursor = useRef<any>();
    const [mouse, setMouse] = useState(
        {
            x: 0,
            y: 0
        }
    )
    // 监听鼠标坐标
    React.useEffect(() => {
        document.addEventListener("mousemove", (e) => {
            setMouse({
                x: e.clientX,
                y: e.clientY
            })
        })
    }, [])
    useLayoutEffect(() => {

        const cursor = $(".cursor"), follower = $(".cursor-follower");

        $("a").on("mouseenter", function () {
            cursor.addClass("active");
            follower.addClass("active");
        });
        $("a").on("mouseleave", function () {
            cursor.removeClass("active");
            follower.removeClass("active");
        });

        $('input').attr('autocomplete', 'off');

        //plan-tab-switcher
        $('.plan-tab-switcher').on('click', function () {
            $(this).toggleClass('active');

            $('.plan-area').toggleClass('change-subs-duration');
        });

        // faq
        $('.faq-wrapper .faq-title').on('click', function (e) {
            var element = $(this).parent('.faq-item');
            if (element.hasClass('open')) {
                element.removeClass('open');
                element.find('.faq-content').removeClass('open');
                element.find('.faq-content').slideUp(300, "swing");
            } else {
                element.addClass('open');
                element.children('.faq-content').slideDown(300, "swing");
                element.siblings('.faq-item').children('.faq-content').slideUp(300, "swing");
                element.siblings('.faq-item').removeClass('open');
                element.siblings('.faq-item').find('.faq-title').removeClass('open');
                element.siblings('.taq-item').find('.faq-content').slideUp(300, "swing");
            }
        });
    }, [])
    useEffect(() => {
        const {x, y} = mouse;
        gsap.to(cursor.current, {
            css: {
                left: x - 4,
                top: y - 4
            },// 在X轴上移动200像素
            duration: 0.2, // 动画持续时间
            ease: "slow(0.7,0.7,false)",// 缓动函数，控制动画的加速度和减速度
        });
        gsap.to(follower.current, {
            css: {
                left: x - 16,
                top: y - 16
            },
            duration: 0.5, // 动画持续时间
            ease: "slow(0.7,0.7,false)", // 缓动函数，控制动画的加速度和减速度
        });


    }, [mouse, cursor, follower]);


    return (
        <>
            <div className={'cursor'} ref={cursor}></div>
            <div className="cursor-follower" ref={follower}></div>
        </>
    )
}
export default Cursor