export default {
    a: "SNSTec - 打造领先的软件解决方案",
    b: "创造非凡的体验",
    c: "你的软件开发和研发解决方案提供商",
    d: "在SNSTec，我们深知在当今的市场环境中，个性化至关重要。然而，我们相信这绝不应以创造力和情感为代价。我们将尖端技术与人文关怀相结合，确保您的讯息不仅个性化，而且具有吸引力和影响力.请相信我们能够提供创新的软件解决方案，与您的受众产生共鸣，推动您的业务向前发展。",
    e: "我们是一家不断进取的IT服务公司",
    f: "我们的座右铭体现了我们组织的核心价值观和理念。我们不遗余力地确保我们的座右铭能够引起团队每位成员的共鸣，并与他们息息相关。",
    g: "我们专注于具有出色性能的软件开发。",
    h: "我们的座右铭概括了我们公司的核心价值观和理念。我们竭尽全力确保我们的座右铭适用于我们团队的每位成员。",

    i: "软件开发",
    j: "我们协助客户打造各种多样的软件解决方案，并不断取得卓越成果。",
    k: "AI、ML和数据分析",
    l: "我们协助客户构建各种数据模型，并进行持续的AI、ML和数据分析，以促进业务增长。",

    m: "有一个项目想法吗？",
    n: "让我们联系起来。",
    o: '我们协助客户打造各种多样的软件解决方案，并不断取得卓越成果。',
    p: "欢迎来到 Snstec，一家致力于企业数字化的软件开发公司。我们优先了解客户的业务挑战、需求和痛点，提供量身定制的软件解决方案，以实现他们特定的目标。",

}