export default {
    whoWeAre:"我们是？",
    a: "在Snstec，我們不僅僅是一家軟件開發公司，我們是您在数字化轉型中的專屬夥伴。我們對品質和創新的承諾確保我們的客戶獲得針對其獨特需求量身定制的强大、高性能軟件。",
    b: "Snstec的核心是一支充滿熱情的專業團隊，他們追求挑戰並擁抱創意。我們深知每個企業都是獨一無二的，因此我們花時間聆聽、學習並與客戶密切合作，以了解他們的目標和挑戰。",
    c: "我們的使命很簡單：通過尖端技術和戰略性軟件解決方案賦能企業。從概念到部署，我们在每一步都为客户提供指导，利用我們在軟件開發领域的專業知識，推動實質成果和可持續增長。",
    d: "在 Snstec，我们相信建立在信任、透明和诚信基础上的长期合作伙伴关系。我们坚定不移地致力于让客户满意，坚持不懈地追求卓越，致力于帮助企业在当今的数字环境中茁壮成长。",
    e: "加入 Snstec，体验创新软件解决方案为您的业务带来的不同。让我们一起踏上旅程，充分释放数字化未来的潜力。",

    f: "Snstec是軟件開發和科技驅動解決方案的領先者Snstec是軟件開發和技术驱动型解決方案的領先者。我們專注於提供全方位的服務，擅長打造為您量身定制的特色軟件解決方案，以滿足您獨特的需求。",

    g: "年持续運營",
    h: "已交付項目",
    i: "名專家團隊",

}