import React from "react";
import {useTranslation} from "react-i18next";

const Strength = () => {


    const {t} = useTranslation();
    return (<section className="agency-section ptb-120">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-12">
                    <div className="agency-content text-center">
                        <div className="agency-logo-text">
                            <span>AGENCY</span>
                        </div>
                        <h2 className="title">{t('about.f')}</h2>
                    </div>
                    <div className="agency-statistics-area">
                        <div className="row justify-content-center mb-30-none">
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 mb-30">
                                <div className="statistics-item">
                                    <div className="statistics-content">
                                        <div className="odo-area">
                                            <h3 className="odo-title odometer" data-odometer-final="12">5</h3>
                                            <h3 className="title">+</h3>
                                        </div>
                                        <p>{t('about.g')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 mb-30">
                                <div className="statistics-item">
                                    <div className="statistics-content">
                                        <div className="odo-area">
                                            <h3 className="odo-title odometer" data-odometer-final="256">18</h3>
                                            <h3 className="title">+</h3>
                                        </div>
                                        <p>{t('about.h')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 mb-30">
                                <div className="statistics-item">
                                    <div className="statistics-content">
                                        <div className="odo-area">
                                            <h3 className="odo-title odometer" data-odometer-final="65">16</h3>
                                            <h3 className="title">+</h3>
                                        </div>
                                        <p>{t('about.i')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}
export default Strength;