import {Link} from "react-router-dom";
import React from "react";
import {useTranslation} from "react-i18next";
import SendMessage from "../components/SendMessage";
import Strength from "../components/Strength";

const About = () => {
    const {t} = useTranslation();
    return (
        <div>

            <section className="banner-section two inner">
                <div className="banner-element-four two">
                    <img src="assets/images/element/element-5.png" alt="element"/>
                </div>
                <div className="banner-element-five two">
                    <img src="assets/images/element/element-7.png" alt="element"/>
                </div>
                <div className="banner-element-nineteen two">
                    <img src="assets/images/element/element-6.png" alt="element"/>
                </div>
                <div className="banner-element-twenty-two two">
                    <img src="assets/images/element/element-69.png" alt="element"/>
                </div>
                <div className="banner-element-twenty-three two">
                    <img src="assets/images/element/element-70.png" alt="element"/>
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center mb-30-none">
                        <div className="col-xl-12 mb-30">
                            <div className="banner-content two">
                                <div className="banner-content-header">
                                    <h2 className="title">{t('app.about')} </h2>
                                    <div className="breadcrumb-area">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    {/*<a href="index.html">Home</a>*/}
                                                    <Link to="/">{t('app.home')} </Link>
                                                </li>
                                                <li className="breadcrumb-item active"
                                                    aria-current="page">{t('app.about')} </li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <a href="#" className="scrollToTop"><i className="las la-angle-double-up"></i></a>

            <section className="about-section ptb-120">
                <div className="about-element-one two">
                    <img src="assets/images/element/element-39.png" alt="element"/>
                </div>
                <div className="container">
                    <div className="row justify-content-center mb-30-none">
                        <div className="col-xl-6 col-lg-6 mb-30">
                            <div className="about-content two">
                                <h3 className="title">{t('about.whoWeAre')}</h3>
                                <p className="para">{t('about.a')}</p>
                                <p>{t('about.b')}</p>
                                <p>{t('about.c')}</p>
                                <p>{t('about.d')}</p>
                                <p>{t('about.e')}</p>
                                <div className="about-btn two">
                                    <Link to="/contact"  className="btn--base">
                                        <a>{t('app.contactUs')}</a>
                                    </Link>
                                    <span>{t('app.orCall')} <a href="tel:11026593268003">+110 2659 3268 003</a></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 mb-30">
                            <div className="about-thumb two">
                                <img src="assets/images/element/element-68.png" alt="element"/>
                                <div className="about-thumb-element-one">
                                    <img src="assets/images/element/element-60.png" alt="element"/>
                                </div>
                                <div className="about-thumb-element-two">
                                    <img src="assets/images/element/element-60.png" alt="element"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Strength/>
            <SendMessage/>
        </div>
    )
}
export default About