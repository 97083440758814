export default {
    whoWeAre:"我们是？",
    a: "在Snstec，我们不仅仅是一家软件开发公司，我们是您在数字化转型中的专属伙伴。我们对品质和创新的承诺确保我们的客户获得针对其独特需求量身定制的强大、高性能软件。",
    b: "Snstec的核心是一支充满热情的专业团队，他们追求挑战并拥抱创意。我们深知每个企业都是独一无二的，因此我们花时间聆听、学习并与客户密切合作，以了解他们的目标和挑战。",
    c: "我们的使命很简单：通过尖端技术和战略性软件解决方案赋能企业。从概念到部署，我们在每一步都为客户提供指导，利用我们在软件开发领域的专业知识，推动实质成果和可持续增长。",
    d: "在 Snstec，我们相信建立在信任、透明和诚信基础上的长期合作伙伴关系。我们坚定不移地致力于让客户满意，坚持不懈地追求卓越，致力于帮助企业在当今的数字环境中茁壮成长。",
    e: "加入 Snstec，体验创新软件解决方案为您的业务带来的不同。让我们一起踏上旅程，充分释放数字化未来的潜力。",

    f: "Snstec是软件开发和科技驱动解决方案的领先者Snstec是软件开发和技术驱动型解决方案的领先者。我们专注于提供全方位的服务，擅长打造为您量身定制的特色软件解决方案，以满足您独特的需求。",

    g: "年持续运营",
    h: "已交付项目",
    i: "名专家团队",

}