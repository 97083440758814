export default {


   a:"SNSTec - Crafting Tomorrow's Software Solutions",
   b:"Exceptional Experiences",
   c:"Best Software Development and R&D Solution Provider",
   d:"At SNSTec, we understand that personalisation is key in today's marketing landscape. However, we believe that it should never come at the cost of creativity and emotion. Our approach blends cutting-edge technology with human touch, ensuring that your messages are not only personalised but also engaging and impactful. Trust us to deliver innovative software solutions that resonate with your audience and drive your business forward.",
   e:"We are a progressive IT service company.",
   f:"Our motto embodies the core values and philosophy of our organisation. We have gone to great lengths to ensure that our motto resonates with and is relevant to every member of our team.",
   g:"We specialise in software development with impressive performance.",
   h:"Our motto encapsulates the core values and philosophy of our company. We have moved heaven and earth to ensure that our motto is applicable to every member of our team.",

   i:"Software Development",
   j:"We aid clients in crafting diverse software solutions that consistently yield outstanding results.",
   k:"AI, ML, and Data Analysis",
   l:"We assist clients in constructing various data models and conducting ongoing AI, ML, and data analysis to foster business growth.",

   m:"Have a project in mind?",
   n:"Let's connect.",
   o:"We aid clients in crafting diverse software solutions that consistently yield outstanding results.",
   p:"Welcome to Snstec, a software development company dedicated to digitising businesses. We prioritise understanding our clients' business challenges, needs, and pain points, delivering software solutions tailored to their specific goals.",

   q:"Snstec, where excellence in software development meets unparalleled client satisfaction. Our commitment to developing robust, high-quality, and performance-driven software. ",
}