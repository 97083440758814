import React from 'react';
import Contact from './pages/contact';
import Header from "./components/Header";
import Home from "./pages/home";
import About from "./pages/about";
import Cursor from "./components/cursor";
import Services from "./pages/services";
import SoftwareDevelopment from "./pages/softwareDevelopment";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import FooterHome from "./components/footerHome";
import Ai from "./pages/ai";

function App() {
    return (
        <Router>
            <div className="App">
                <Cursor/>
                <Header></Header>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/services" element={<Services/>}/>
                    <Route path="/softwareDevelopment" element={<SoftwareDevelopment/>}/>
                    <Route path="/ai" element={<Ai/>}/>
                </Routes>
                {/*<Footer></Footer>*/}
                <FooterHome/>
            </div>
        </Router>
    );
}

export default App;
