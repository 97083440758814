import {Link} from "react-router-dom";
import React from "react";
import {useTranslation} from "react-i18next";

const FooterHome = () => {

    const {t } = useTranslation();
    return <footer className="footer-section two">
        <div className="footer-element-three">
            <img src="assets/images/element/element-40.png" alt="element"/>
        </div>
        <div className="footer-element-seven two">
            <img src="assets/images/element/element-39.png" alt="element"/>
        </div>
        <div className="footer-element-eight">
            <img src="assets/images/element/element-7.png" alt="element"/>
        </div>
        <div className="footer-area ptb-120">
           {/* <div className="footer-area-element">
                <img src="assets/images/element/element-57.png" alt="element"/>
            </div>*/}
            <div className="container">
                <div className="footer-top-area">
                    <div className="row mb-30-none">
                        <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                            <div className="footer-widget">
                                <ul className="footer-contact-list">
                                    <li>
                                        <span className="sub-title">{t('app.callUs')}</span>
                                        <h4 className="link-title"><a href="tel:0369569032">+11 0369 569 032</a></h4>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                            <div className="footer-widget">
                                <ul className="footer-contact-list">
                                    <li>
                                        <span className="sub-title">{t('app.writeToUs')}</span>
                                        <h4 className="link-title"><a href="mailto:">info@example.com</a></h4>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                            <div className="footer-widget">
                                <ul className="footer-contact-list">
                                    <li>
                                        <span className="sub-title">{t('app.officeHours')}</span>
                                        <h4 className="link-title">{t('app.time')}</h4>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom-area">
                    <div className="row mb-30-none">
                        <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                            <div className="footer-widget">
                                <h5 className="title">{t('app.aboutUs')}</h5>
                                <p>{t('app.descUs')}</p>
                                <ul className="footer-social">
                                    <li><a href="#0"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a href="#0"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="#0"><i className="fab fa-google-plus-g"></i></a></li>
                                    <li><a href="#0"><i className="fab fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-6 mb-30">
                            <div className="footer-widget">
                                <h4 className="title">Explore Softim</h4>
                                <ul className="footer-list">
                                    <li><a href="#0">Account</a></li>
                                    <li><a href="#0">Privacy Policy</a></li>
                                    <li><a href="#0">Affilitate</a></li>
                                    <li><a href="#0">Program</a></li>
                                    <li><a href="#0">Our Partner</a></li>
                                </ul>
                            </div>
                        </div>
                        {/*<div className="col-xl-2 col-lg-2 col-md-6 mb-30">
                            <div className="footer-widget">
                                <h5 className="title">Quick Links</h5>
                                <ul className="footer-list">
                                    <li><a href="#0">Account</a></li>
                                    <li><a href="#0">Privacy Policy</a></li>
                                    <li><a href="#0">Affilitate</a></li>
                                    <li><a href="#0">Program</a></li>
                                    <li><a href="#0">Our Partner</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                            <div className="footer-widget">
                                <h5 className="title">Sign up Newsletter</h5>
                                <form className="footer-subscribe-form">
                                    <input type="email" className="form--control" placeholder="Enter Mail"/>
                                    <button type="submit"><i className="las la-angle-right"></i></button>
                                </form>
                            </div>
                        </div>*/}
                    </div>
                </div>
            </div>
        </div>
        <div className="copyright-wrapper two">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-12 text-center">
                        <div className="copyright-area">
                            <div className="footer-logo">
                                <Link to="/" className="site-logo site-title">
                                    <img src="assets/images/SNSTec_HK_Logo.png" alt="site-logo"/>
                                </Link>
                                {/*
                                <a className="site-logo site-title" href="index.html">
                                    <img src="assets/images/SNSTec_HK_Logo.png" alt="site-logo"/>
                                </a>*/}
                            </div>
                            <p>Copyright © 2024 <Link to="/">SNSTec Ltd</Link>. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
}
export default FooterHome