import React from "react";
import {Link} from "react-router-dom";
import Language from "./Language";
import {useTranslation} from "react-i18next";

const Header = () => {
    const {t } = useTranslation();
    return <header className="header-section three">
        <div className="header">
            <div className="header-bottom-area">
                <div className="container custom-container">
                    <div className="header-menu-content">
                        <nav className="navbar navbar-expand-xl p-0">
                            {/*<a className="site-logo site-title" href="index.html">*/}
                            <Link to="/">
                                <img src="assets/images/SNSTec_HK_Logo.png" alt="site-logo"/>
                            </Link>
                            {/*</a>*/}
                            <button className="navbar-toggler d-block d-xl-none ml-auto" type="button"
                                    data-toggle="collapse" data-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent" aria-expanded="false"
                                    aria-label="Toggle navigation">
                                <span className="toggle-bar"></span>
                            </button>
                            <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                                <ul className="navbar-nav main-menu">
                                    <li className="menu_has_children">
                                        {/*<a href="index.html">Home</a>*/}
                                        <Link to="/">{t('app.home')}</Link>
                                    </li>
                                    <li>
                                        {/*<a href="about.html">About</a>*/}

                                        <Link to="/about">{t('app.about')}</Link>
                                    </li>
                                    <li className="menu_has_children">
                                        <a href="#0">{t('app.services')} <i className="las la-angle-down"></i></a>
                                        <ul className="sub-menu">
                                            <li>
                                                {/*<a href="service.html">Service One</a>*/}

                                                <Link to="/services">{t('app.service')}</Link>
                                            </li>
                                            <li>
                                                <Link to="/softwareDevelopment">{t('app.softwareDevelopment')}</Link>
                                            </li>
                                            <li>
                                                <Link to="/ai">{t('app.ai')}</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="/contact">{t('app.contact')}</Link>
                                        {/*<a href="contact.html">Contact</a>*/}
                                    </li>
                                    <Language/>
                                </ul>
                                <div className="header-right">
                                    <div className="header-action-area">
                                        <div className="header-action">

                                            <Link className="btn--base" to="/contact">{t('app.getStarted')}</Link>
                                            {/*<a href="contact.html" className="btn--base">GET STARTED</a>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </header>
}
export default Header;