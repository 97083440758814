export default {
    a: 'Visit Our Office',
    b: 'Call Us',
    c: 'Mail Us',
    d: 'Enter Name',
    e: 'Email Address',
    f: 'Your Phone',
    g: 'Select Subject',
    g1:'Web Development',
    g2:'Web Design',
    g3:'Digital Marketing',
    g4:'Search SEO',
    h: 'Write Message',
    i: 'Send Now',
}