import {useTranslation} from "react-i18next";

const Ability:React.FC<{ability:string}> =({ability})=>{
    const {t } = useTranslation();
    return (<section className="service-section two ptb-60">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-12">
                    <div className="service-item three details">

                        <div className="service-content">
                            <h2 className="title">{t(`servicesDetail.${ability}.j`)}</h2>
                            <p>{t(`servicesDetail.${ability}.k`)}</p>
                            <div className="service-widget-item-area">
                                <div className="row justify-content-center mb-30-none">
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 mb-30">
                                        <div className="service-widget-item">
                                            <div className="service-widget-icon">
                                                <img src="assets/images/icon/icon-17.png" alt="icon"/>
                                            </div>
                                            <div className="service-widget-content">
                                                <h5 className="title">CUSTOMIZED STYLE</h5>
                                                <span className="sub-title">Pre Designed</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 mb-30">
                                        <div className="service-widget-item">
                                            <div className="service-widget-icon">
                                                <img src="assets/images/icon/icon-18.png" alt="icon"/>
                                            </div>
                                            <div className="service-widget-content">
                                                <h5 className="title">IN TIME RESULT</h5>
                                                <span className="sub-title">Developer Time</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 mb-30">
                                        <div className="service-widget-item">
                                            <div className="service-widget-icon">
                                                <img src="assets/images/icon/icon-19.png" alt="icon"/>
                                            </div>
                                            <div className="service-widget-content">
                                                <h5 className="title">PIXEL PERFECT</h5>
                                                <span className="sub-title">Design Time</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 mb-30">
                                        <div className="service-widget-item">
                                            <div className="service-widget-icon">
                                                <img src="assets/images/icon/icon-20.png" alt="icon"/>
                                            </div>
                                            <div className="service-widget-content">
                                                <h5 className="title">SEO FRIENDLY</h5>
                                                <span className="sub-title">Development</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="service-bottom-content">
                                <h2 className="title">{t(`servicesDetail.${ability}.l`)}</h2>
                                <p>{t(`servicesDetail.${ability}.m`)}</p>
                                <p>{t(`servicesDetail.${ability}.n`)}</p>
                                <p>{t(`servicesDetail.${ability}.o`)}</p>
                                <div className="sevice-inner-item-area">
                                    <div className="row justify-content-center mb-30-none">
                                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 mb-30">
                                            <div className="service-inner-item">
                                                <div className="service-inner-icon">
                                                    <img src="assets/images/icon/icon-14.png" alt="icon"/>
                                                </div>
                                                <div className="service-inner-content">
                                                    <h4 className="title">Over 1500 DVSA certified Develop web
                                                        product</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 mb-30">
                                            <div className="service-inner-item">
                                                <div className="service-inner-icon">
                                                    <img src="assets/images/icon/icon-15.png" alt="icon"/>
                                                </div>
                                                <div className="service-inner-content">
                                                    <h4 className="title">Free access to our award winning road
                                                        brain stroming</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 mb-30">
                                            <div className="service-inner-item">
                                                <div className="service-inner-icon">
                                                    <img src="assets/images/icon/icon-16.png" alt="icon"/>
                                                </div>
                                                <div className="service-inner-content">
                                                    <h4 className="title">Successful Project Delivery System in
                                                        our team</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <blockquote className="two">
                                    <div className="quote-area d-flex flex-wrap">
                                        <div className="quote-icon">
                                            <img src="assets/images/client/quote-2.png" alt="quote"/>
                                        </div>
                                        <div className="quote-shape">
                                            <img src="assets/images/element/element-66.png" alt="element"/>
                                        </div>
                                        <div className="quote-content-area">
                                            <p className="quote-content">{t(`servicesDetail.${ability}.p`)}</p>
                                        </div>
                                    </div>
                                </blockquote>
                                <p>{t(`servicesDetail.${ability}.q`)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}
export default Ability