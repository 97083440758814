import home from './home'
import about from './about'
import services from './services'
import servicesDetail from './servicesDetail'
import contact from './contact'
import app from './app'
export default {
    app,
    home,
    about,
    services,
    servicesDetail,
    contact
}