import React from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Ability from "../components/Ability";

const SoftwareDevelopment = () => {
    const {t} = useTranslation();
    return (
        <div>

            <section className="banner-section two inner">
                <div className="banner-element-four two">
                    <img src="assets/images/element/element-5.png" alt="element"/>
                </div>
                <div className="banner-element-five two">
                    <img src="assets/images/element/element-7.png" alt="element"/>
                </div>
                <div className="banner-element-nineteen two">
                    <img src="assets/images/element/element-6.png" alt="element"/>
                </div>
                <div className="banner-element-twenty-two two">
                    <img src="assets/images/element/element-69.png" alt="element"/>
                </div>
                <div className="banner-element-twenty-three two">
                    <img src="assets/images/element/element-70.png" alt="element"/>
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center mb-30-none">
                        <div className="col-xl-12 mb-30">
                            <div className="banner-content two">
                                <div className="banner-content-header">
                                    <h2 className="title">{t('app.softwareDevelopment')} </h2>
                                    <div className="breadcrumb-area">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    {/*<a href="index.html">Home</a>*/}
                                                    <Link to="/">{t('app.home')} </Link>
                                                </li>
                                                <li className="breadcrumb-item active" aria-current="page">{t('app.softwareDevelopment')}
                                                </li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <a href="#" className="scrollToTop"><i className="las la-angle-double-up"></i></a>
            <section className="service-section two ptb-60">
                <div className="container">
                    <div className="service-thumb">
                        <img src="assets/images/service.png" alt="service"/>
                    </div>
                </div>
            </section>
            <Ability ability={'dev'}/>
            <section className="service-section two  ptb-60">
                <div className="container">
                    <div className="contact-section two">
                        <div className="contact-area">
                            <div className="contact-element-five">
                                <img src="assets/images/element/element-60.png" alt="element"/>
                            </div>
                            <div className="contact-element-six">
                                <img src="assets/images/element/element-60.png" alt="element"/>
                            </div>
                            <div className="row mb-30-none">
                                <div className="col-xl-5 col-lg-5 mb-30">
                                    <div className="contact-thumb">
                                        <img src="assets/images/contact.png" alt="contact"/>
                                    </div>
                                </div>
                                <div className="col-xl-7 col-lg-7 mb-30">
                                    <div className="contact-form-area">
                                        <div className="contact-form-header">
                                            <div className="left">
                                                <h2 className="title">Get in Touch <span
                                                    className="text--base">Let's Talk</span></h2>
                                                <p>Credibly grow premier ideas rather than
                                                    bricks-and-clicks strategic theme areas.</p>
                                            </div>
                                        </div>
                                        <form className="contact-form">
                                            <div className="row justify-content-center mb-30-none">
                                                <div className="col-xl-6 col-lg-6 form-group">
                                                    <input type="text" className="form--control"
                                                           placeholder="Your Name"/>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 form-group">
                                                    <input type="email" className="form--control"
                                                           placeholder="Your Email"/>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 form-group">
                                                    <input type="text" className="form--control"
                                                           placeholder="Phone Number"
                                                           onInput={() => {
                                                               // this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
                                                           }

                                                           }/>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 form-group">
                                                    <div className="contact-select">
                                                        <select className="form--control">
                                                            <option value="1">Service Required
                                                            </option>
                                                            <option value="2">Web Design</option>
                                                            <option value="3">Digital Marketing
                                                            </option>
                                                            <option value="4">Search SEO</option>
                                                            <option value="5">Web Development
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 form-group">
                                                                        <textarea className="form--control"
                                                                                  placeholder="Write Message..."></textarea>
                                                </div>
                                                <div
                                                    className="col-xl-12 form-group custom-form-group mt-20">
                                                    <div className="form-group custom-check-group">
                                                        <input type="checkbox" id="level-1"/>
                                                        <label htmlFor="level-1">I'm Agree With
                                                            Softim <a href="#0"
                                                                      className="text--base">Terms &
                                                                Conditions</a></label>
                                                    </div>
                                                    <button type="submit" className="btn--base">Send
                                                        Message
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default SoftwareDevelopment