export default {
    home: "主页",
    about: "关于我们",
    services: "服务",
    service: "服务概览",
    softwareDevelopment: "软件开发",
    ai: "Ai",
    contact: "联系我们",
    language: "语言",
    zh: "中文",
    en: "English",
    tw: "繁體",
    getStarted: "开始行动",
    // footer
    callUs: '联系电话',
    writeToUs: "电子邮件",
    officeHours: "工作时间",
    time: "周一 至 周五 09:00 - 18:00",
    aboutUs: '关于我们',
    descUs: 'Snstec，卓越的软件开发与无与伦比的客户满意度相得益彰。我們致力於開發強大、高品質和以性能為驅動的軟件。',

    contactUs: '联系我们',
    orCall: '或呼叫',
    knowMore: '了解更多',
    yearsOfExperience: '年经验',
    experiencedEmployees: '经验丰富的员工',
    viewAllServices: '更多服务',
    sendMessage: '联系我们',
}