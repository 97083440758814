export default {
    home: "Home",
    about: "About",
    services: "Services",
    service: "Service",
    softwareDevelopment: "Software Development",
    ai: "Ai",
    contact: "Contact",
    language: "Language",
    zh: "中文",
    en: "English",
    tw: "繁體",
    getStarted: "Get Started",
    // footer


    callUs:'CALL US',
    writeToUs:"WRITE TO US",
    officeHours:"OFFICE HOURS",
    time:"Mon-Sat 09:00 - 18:00",
    aboutUs:"About us",
    descUs:'Snstec, where excellence in software development meets unparalleled client satisfaction. Our commitment to developing robust, high-quality, and performance-driven software. ',

    contactUs: 'Contact Us',
    orCall: 'or Call',
    knowMore: 'Know More',
    yearsOfExperience: 'Years of Experience',
    experiencedEmployees: 'Experienced Employees',
    viewAllServices: 'View All Services',
    sendMessage: 'Send Message',
}