import {useTranslation} from "react-i18next";
import React, {useEffect} from "react";
import {LanguageEnum} from "../const/enum";
import {queryIp} from "../utils/ipQuery";
import {useDispatch} from 'react-redux';
import {changeLanguage} from "../store/appState";

const Language = () => {
    const {t, i18n} = useTranslation();

    const dispatch = useDispatch();
    const setLanguage = async () => {
        const res = await queryIp();
        if (res !== null) {
            switch (res.countryCode) {
     /*           case 'HK':
                    languageChange(LanguageEnum.tw);
                    break;
                case 'TW':
                    languageChange(LanguageEnum.tw);
                    break;
                case 'MO':
                    languageChange(LanguageEnum.tw);
                    break;*/
                case 'CN':
                    languageChange(LanguageEnum.zh);
                    break;
                default:
                    languageChange(LanguageEnum.en);
            }
        }
    }
    const languageChange = (language: LanguageEnum) => {
        i18n.changeLanguage(language).then();
        dispatch(changeLanguage(language));
    };
    useEffect(() => {
        setLanguage();
    }, []);
    return (<li className="menu_has_children">
            <a href="#0">{t('app.language')} <i className="las la-angle-down"></i></a>
            <ul className="sub-menu" style={{left: 0}}>
                <li>
                    <a onClick={() => languageChange(LanguageEnum.en)}>English</a>
                </li>
                <li>
                    <a onClick={() => languageChange(LanguageEnum.zh)}>中文</a>
                </li>
                {/*<li>
                    <a onClick={() => languageChange(LanguageEnum.tw)}>繁體中文</a>
                </li>*/}
            </ul>
        </li>
    )
}
export default Language