import {FC, useEffect} from "react";
import AMapLoader from "@amap/amap-jsapi-loader";
import location from '../images/logo/location.png'
import {useSelector} from "react-redux";
import {AppState} from "../store/appState";
import {LanguageEnum} from "../const/enum";

export const hkCoordinate = [114.176800, 22.275614];
export const hqCoordinate = [113.524185, 22.154737];


const GaoDeMap = () => {
    let map: { destroy: () => void; } | null = null;
    const mapStyle = {height: "550px", width: '100%', padding: 0, margin: 0}
    const appState = useSelector(({appState}: any) => appState);

    useEffect(() => {
        const coordinate = appState?.language === LanguageEnum.zh ? hqCoordinate : hkCoordinate
        AMapLoader.load({
            key: "0a9ce664321fc70ca77dd303cd18f226", // 申请好的Web端开发者Key，首次调用 load 时必填
            version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        })
            .then((AMap) => {
                map = new AMap.Map("container", {
                    // 设置地图容器id
                    viewMode: "3D", // 是否为3D地图模式
                    zoom: 17, // 初始化地图级别
                    center: coordinate, // 初始化地图中心点位置=, // 初始化地图中心点位置
                });
                const marker = new AMap.Marker({
                    icon: location,
                    position: coordinate,
                    // offset: new AMap.Pixel(-13, -30),
                });
                marker.setMap(map);
            })
            .catch((e) => {
                console.log(e);
            });

        return () => {
            map?.destroy();
        };
    }, [ appState?.language ]);

    return (
        /*<div>
            <div>{JSON.stringify(appState)}</div>
            <div>{appState?.language}</div>
        </div>*/
    <div id="container" style={mapStyle}></div>
);
}
export default GaoDeMap