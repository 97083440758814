export default {
    a: "SNSTec - 打造领先的软件解决方案",
    b: "创造非凡的体验",
    c: "你的軟件開發和研發解決方案提供商",
    d: "在SNSTec，我們深知在當今的市場環境中，個性化至關重要。然而，我們相信這絕不應以創造力和情感為代價。我们将尖端技术与人文关怀相结合，確保您的訊息不僅個性化，而且具有吸引力和影响力.请相信我们能够提供创新的软件解决方案，與您的受眾產生共鳴，推動您的業務向前發展。",
    e: "我們是一家不断进取的IT服務公司",
    f: "我們的座右銘體現了我們組織的核心價值觀和理念。我们不遗余力地确保我们的座右铭能够引起团队每位成员的共鸣，并与他们息息相关。",
    g: "我們專注於具有出色性能的軟件開發。",
    h: "我們的座右銘概括了我們公司的核心價值觀和理念。我們竭盡全力確保我們的座右銘適用於我們團隊的每位成員。",

    i: "軟件開發",
    j: "我們協助客戶打造各種多樣的軟件解決方案，并不断取得卓越成果。",
    k: "AI、ML和數據分析",
    l: "我們協助客戶構建各種數據模型，並進行持續的AI、ML和數據分析，以促進業務增長。",

    m: "有一個項目想法嗎？",
    n: "讓我們聯繫起來。",
    o: '我們協助客戶打造各種多樣的軟件解決方案，并不断取得卓越成果。',
    p: "歡迎來到 Snstec，一家致力于企业数字化的软件开发公司。我們優先了解客戶的業務挑戰、需求和痛點，提供量身定制的軟件解決方案，以實現他們特定的目標。",

}