import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {LanguageEnum} from "../const/enum";

export interface AppState {
    language: LanguageEnum;
}

const appState: AppState = {
    language: LanguageEnum.en,
};

// 创建slice

const appStateSlice = createSlice({
    name: 'appState',
    initialState: appState,
    reducers: {
        changeLanguage: (state, action: PayloadAction<LanguageEnum>) => {
            state.language = action.payload;
        },
    },
});

export const {changeLanguage} = appStateSlice.actions;
export default appStateSlice.reducer

/*
// 从slice中解构出action creators

// 使用store.dispatch来触发action
store.dispatch(changeLanguage( LanguageEnum.zh));

// 获取当前状态
const currentState = store.getState();
console.log(currentState.language);*/
