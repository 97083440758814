import React, {useEffect, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import GaoDeMap, {hkCoordinate, hqCoordinate} from "../components/GaoDeMap";
import GoogleMap from "../components/GoogleMap";
import {useSelector} from "react-redux";
import {LanguageEnum} from "../const/enum";
import {useTranslation} from "react-i18next";

const HKCompanyInfo = [
    {
        icon: "las la-map-marked-alt",
        title: 'Visit Our Office',
        href: '#0',
        detail: '72 Main Drive, Calibry, FL'
    }, {
        icon: "las la-phone",
        title: 'Call Us',
        href: 'tel:19006963600',
        detail: <a href="tel:19006963600">+1 (900) 696 3600</a>
    }, {
        icon: "las la-envelope-open-text",
        title: 'Mail Us',
        href: 'mailto:',
        detail: 'softim@yahoo.com'
    },
]
const ZHCompanyInfo = [
    {
        icon: "las la-map-marked-alt",
        title: '地址',
        href: '#0',
        detail: '横琴富力中心 1621 - 1622 室'
    }, {
        icon: "las la-phone",
        title: '电话',
        href: 'tel:19006963600',
        detail: <a href="tel:19006963600">+1 (900) 696 3600</a>
    }, {
        icon: "las la-envelope-open-text",
        title: '邮件',
        href: 'mailto:',
        detail: 'softim@yahoo.com'
    },
]
const Contact = () => {
    const appState = useSelector(({appState}: any) => appState);
    const {t} = useTranslation();

    const companyInfo = useMemo(() => {
        const info = appState.language === LanguageEnum.zh ? ZHCompanyInfo : HKCompanyInfo
        return (
            info.map((item) => {
                return (<div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 mb-30" key={item.title}>
                    <div className="contact-item text-center">
                        <div className="contact-icon-area">
                            <div className="contact-icon">
                                <i className={item.icon}></i>
                            </div>
                        </div>
                        <div className="contact-content">
                            <h3 className="title">{item.title}</h3>
                            <p><a href={item.href}>{item.detail}</a></p>
                        </div>
                    </div>
                </div>)
            })
        )
    }, [appState.language]);
    return (<div>

            <section className="banner-section two inner">
                <div className="banner-element-four two">
                    <img src="assets/images/element/element-5.png" alt="element"/>
                </div>
                <div className="banner-element-five two">
                    <img src="assets/images/element/element-7.png" alt="element"/>
                </div>
                <div className="banner-element-nineteen two">
                    <img src="assets/images/element/element-6.png" alt="element"/>
                </div>
                <div className="banner-element-twenty-two two">
                    <img src="assets/images/element/element-69.png" alt="element"/>
                </div>
                <div className="banner-element-twenty-three two">
                    <img src="assets/images/element/element-70.png" alt="element"/>
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center mb-30-none">
                        <div className="col-xl-12 mb-30">
                            <div className="banner-content two">
                                <div className="banner-content-header">
                                    <h2 className="title">{t('app.contact')} </h2>
                                    <div className="breadcrumb-area">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to="/">{t('app.home')}</Link>
                                                    {/*<a href="index.html">Home</a>*/}
                                                </li>
                                                <li className="breadcrumb-item active" aria-current="page">{t('app.contact')}</li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <a href="#" className="scrollToTop"><i className="las la-angle-double-up"></i></a>
            <section className="contact-item-section ptb-120">
                <div className="container">
                    <div className="row justify-content-center mb-30-none">
                        {
                            companyInfo
                            /*ZHCompanyInfo.map((item) => {
                                return (<div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 mb-30" key={item.title}>
                                    <div className="contact-item text-center">
                                        <div className="contact-icon-area">
                                            <div className="contact-icon">
                                                <i className={item.icon}></i>
                                            </div>
                                        </div>
                                        <div className="contact-content">
                                            <h3 className="title">{item.title}</h3>
                                            <p><a href={item.href}>{item.detail}</a></p>
                                        </div>
                                    </div>
                                </div>)
                            })*/
                        }
                    </div>
                </div>
            </section>
            <div className="map-area">
                <GaoDeMap/>
            </div>
            <div className="contact-section ptb-120">
                <div className="contact-element-one">
                    <img src="assets/images/element/element-73.png" alt="element"/>
                </div>
                <div className="contact-element-two">
                    <img src="assets/images/element/element-74.png" alt="element"/>
                </div>
                <div className="container">
                    <form className="contact-form">
                        <div className="row justify-content-center mb-25-none">
                            <div className="col-xl-6 col-lg-6 form-group">
                                <label>{t('contact.d')}</label>
                                <input type="text" name="name" className="form--control"
                                       placeholder="Jhon david smith"/>
                            </div>
                            <div className="col-xl-6 col-lg-6 form-group">
                                <label>{t('contact.e')}</label>
                                <input type="email" name="email" className="form--control"
                                       placeholder="jhonsmith@gmail.com"/>
                            </div>
                            <div className="col-xl-6 col-lg-6 form-group">
                                <label>{t('contact.f')}</label>
                                <input type="text" name="number" className="form--control"
                                       placeholder="+1 (900) 696 3600" onInput={() => {
                                    // this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
                                }}/>
                            </div>
                            <div className="col-xl-6 col-lg-6 form-group">
                                <label>{t('contact.g')}</label>
                                <div className="contact-select">
                                    <select className="form--control">
                                        <option value="1">{t('contact.g1')}</option>
                                        <option value="2">{t('contact.g2')}</option>
                                        <option value="3">{t('contact.g3')}</option>
                                        <option value="4">{t('contact.g4')}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-xl-12 col-lg-12 form-group">
                                <label>{t('contact.h')} </label>
                                <textarea name="message" className="form--control"
                                          placeholder="Write Here ..."></textarea>
                            </div>
                            <div className="col-xl-12 col-lg-12 form-group text-center">
                                <button type="submit" className="btn--base mt-20">{t('contact.i')} <i
                                    className="fas fa-arrow-right ml-2"></i></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Contact