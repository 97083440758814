import React from 'react';
import {Link} from "react-router-dom";
import icon3 from '../images/icon/icon-3.png'
import icon4 from '../images/icon/icon-4.png'
import icon5 from '../images/icon/icon-5.png'
import icon6 from '../images/icon/icon-6.png'
import {useTranslation} from "react-i18next";
import SendMessage from "../components/SendMessage";

const Home = () => {
    const {t } = useTranslation();
    const list=[
        {
            icon:icon3,
            title:t('home.i'),
            desc:t('home.j'),
            route:'/softwareDevelopment'
        },{
            icon:icon4,
            title:t('home.k'),
            desc:t('home.l'),
            route:'/ai'
        }/*,{
            icon:icon5,
            title:'Business Consulting',
            desc:'We rank among the best in the US, Argentina, and Ukraine. Our apps get',
            route:'/service'
        },{
            icon:icon6,
            title:'QA & Testing',
            desc:'We rank among the best in the US, Argentina, and Ukraine. Our apps get',
            route:'/service'
        },*/
    ]
    return (
        <div>

            <section className="banner-section two">
                <div className="banner-element-four">
                    <img src="assets/images/element/element-6.png" alt="element" />
                </div>
                <div className="banner-element-five">
                    <img src="assets/images/element/element-7.png" alt="element" />
                </div>
                <div className="banner-element-eightteen">
                    <img src="assets/images/element/element-53.png" alt="element" />
                </div>
                <div className="banner-element-nineteen">
                    <img src="assets/images/element/element-6.png" alt="element" />
                </div>
                <div className="banner-element-twenty">
                    <img src="assets/images/element/element-7.png" alt="element" />
                </div>
                <div className="banner-element-twenty-one">
                    <img src="assets/images/element/element-7.png" alt="element" />
                </div>
                <div className="banner-element-twenty-two">
                    <img src="assets/images/element/element-54.png" alt="element" />
                </div>
                <div className="banner-element-twenty-three">
                    <img src="assets/images/element/element-55.png" alt="element" />
                </div>
                <div className="banner-element-twenty-four">
                    <img src="assets/images/element/element-56.png" alt="element" />
                </div>
                <div className="banner-element-twenty-six">
                    <img src="assets/images/element/element-64.png" alt="element" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center mb-30-none">
                        <div className="col-xl-12 mb-30">
                            <div className="banner-content two">
                                <div className="banner-content-header" data-aos="fade-up" data-aos-duration="1200">
                                    <h1 className="title">{t('home.a')}</h1>
                                    <h1 className="title">{t('home.b')}</h1>
                                </div>
                                <div className="banner-area" >
                                    <div className="banner-text">
                                        <span>TECH</span>
                                    </div>
                                    <div className="banner-left-content">
                                        <div  className='empty-holder'></div>
                                        <div className="banner-left-footer">
                                            <h3 className="title">{t('home.c')}</h3>
                                            <p>{t('home.d')}</p>
                                            <div className="banner-left-btn">
                                                <Link to="/contact"  className="btn--base active">{t('app.contactUs')}</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="banner-thumb">
                                        <img src="assets/images/element/element-52.png" alt="element" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <a href="#" className="scrollToTop"><i className="las la-angle-double-up"></i></a>
            <section className="statistics-section pb-120">
                <div className="statistics-element-one">
                    <img src="assets/images/element/element-39.png" alt="element" />
                </div>
                <div className="statistics-element-two">
                    <img src="assets/images/element/element-60.png" alt="element" />
                </div>
                <div className="statistics-element-three">
                    <img src="assets/images/element/element-26.png" alt="element" />
                </div>
                <div className="container">
                    <div className="row justify-content-center mb-30-none">
                        <div className="col-xl-6 col-lg-6 mb-30">
                            <div className="statistics-left-content">
                                <h2 className="title">{t('home.e')}</h2>
                                <p>{t('home.f')}</p>
                                <div className="statistics-left-btn">
                                    <Link to="/about" className="custom-btn">{t('app.knowMore')}</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 mb-30">
                            <div className="statistics-item-area">
                                <div className="row mb-30-none">
                                    <div className="col-xl-6 col-lg-6 col-md-6 mb-30">
                                        <div className="statistics-item">
                                            <div className="statistics-icon">
                                                <img src="assets/images/icon/icon-8.png" alt="icon" />
                                            </div>
                                            <div className="statistics-content">
                                                <div className="odo-area">
                                                    <h3 className="odo-title odometer" data-odometer-final="10">10</h3>
                                                    <h3 className="title">+</h3>
                                                </div>
                                                <p>{t('app.yearsOfExperience')}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 mb-30">
                                        <div className="statistics-item">
                                            <div className="statistics-icon">
                                                <img src="assets/images/icon/icon-9.png" alt="icon" />
                                            </div>
                                            <div className="statistics-content">
                                                <div className="odo-area">
                                                    <h3 className="odo-title odometer" data-odometer-final="100">100</h3>
                                                    <h3 className="title">%</h3>
                                                </div>
                                                <p>{t('app.experiencedEmployees')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="overview-section pb-120">
                <div className="overview-element">
                    <img src="assets/images/element/element-24.png" alt="element" />
                </div>
                <div className="container">
                    <div className="row justify-content-center mb-30-none">
                        <div className="col-xl-6 col-lg-6 mb-30">
                            <div className="overview-thumb">
                                <img src="assets/images/element/element-61.png" alt="element" />
                                    <div className="overview-thumb-element">
                                        <img src="assets/images/element/element-62.png" alt="element" />
                                    </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 mb-30">
                            <div className="overview-content">
                                <h2 className="title">{t('home.g')}</h2>
                                <p>{t('home.h')}</p>
                                <div className="overview-btn">

                                    <Link to="/services" className="btn--base active">{t('app.viewAllServices')}</Link>
                                    {/*<a href="service.html" className="btn--base active">View All Services</a>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="service-section two pb-120">
                <div className="service-element-one two">
                    <img src="assets/images/element/element-23.png" alt="element" />
                </div>
                <div className="container">
                    <div className="row justify-content-center mb-60-none">
                        {
                            list.map((item, index) => {
                                    return (

                                        // col-xl-3 col-lg-4
                                        <div className="col-md-6 col-sm-6 mb-60" key={index}>
                                            <div className="service-item two">
                                                <div className="service-icon">
                                                    <img src={item.icon} alt="icon" />
                                                </div>
                                                <div className="service-content">
                                                    <h3 className="title">{item.title}</h3>
                                                    <p style={{minHeight:'55px'}}>{item.desc}</p>
                                                    <div className="service-btn">
                                                        {/*<a href="service.html" className="custom-btn">Learn More <i className="icon-Group-2361 ml-2"></i></a>*/}
                                                        <Link to={item.route} className="custom-btn">{t('app.viewAllServices')}<i className="icon-Group-2361 ml-2"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            )
                        }
                    </div>
                </div>
            </section>
            <SendMessage/>
        </div>
)
}
export default  Home